import "./App.scss";
import React, { useEffect, useState, useCallback } from "react";
import bell from "./bell.mp3";
import silent from "./silent.mp3";
import version from "./version.json";

let lastMessageTime = new Date().getTime();
const log = (...msg) => {
  try {
    const now = new Date().getTime();
    const delta = now - lastMessageTime;
    lastMessageTime = now;
    document
      .querySelector("#log")
      .prepend(delta + "\t" + msg.join(", ") + "\n");
  } catch (e) {}
  console.log(...msg);
};

const audio = new Audio();
const tryAudio = () => {
  audio.src = silent;
  audio.play().catch(e => console.error(e));
};

const assertAudioEnabled = async () => {
  try {
    audio.src = silent;
    await audio.play();
    return true;
  } catch (e) {
    return e.name !== "NotAllowedError";
  }
};

const TimerNumbers = React.memo(({ mirror }) => {
  const numbers = [];
  for (let i = 0; i < 60; i += 5) {
    const degree = mirror ? (60 - i) * 6 : i * 6;
    const x = Math.sin((degree * Math.PI) / 180) * 60 + 50;
    const y = -Math.cos((degree * Math.PI) / 180) * 60 + 52;

    numbers.push(
      <text
        key={i}
        x={x}
        textAnchor="middle"
        fontSize="6"
        fontWeight="bold"
        fontFamily="Helvetica, Helvetica Neue, Arial, sans-serif"
        y={y}
      >
        {i}
      </text>
    );
  }
  return numbers;
});

const TimerTicks = React.memo(() => {
  const ticks = [];
  for (let i = 0; i < 60; i++) {
    const isSignificant = i % 5 === 0;

    const degree = i * 6;
    const x1 =
      Math.sin((degree * Math.PI) / 180) * (52 + isSignificant * 2) + 50;
    const x2 =
      Math.sin((degree * Math.PI) / 180) * (48 - isSignificant * 2) + 50;
    const y1 =
      -Math.cos((degree * Math.PI) / 180) * (52 + isSignificant * 2) + 50;
    const y2 =
      -Math.cos((degree * Math.PI) / 180) * (48 - isSignificant * 2) + 50;

    ticks.push(
      <line
        key={i}
        stroke="black"
        strokeWidth={i % 5 === 0 ? "2" : "0.5"}
        {...{ x1, x2, y1, y2 }}
      />
    );
  }

  return ticks;
});

const calculatePath = degree => {
  const x = Math.sin((degree * Math.PI) / 180) * 50 + 50;
  const y = -Math.cos((degree * Math.PI) / 180) * 50 + 50;

  const flags = degree >= 180 ? "0 1 1" : "1 0 1";
  return `M50,50 v-50 A 50 50 ${flags} ${x} ${y}`;
};

const padZero = n => (n > 9 ? n : "0" + n);
const prettyPrintTime = timeLeft => {
  if (isNaN(timeLeft)) return "00:00";
  const minutes = (Math.ceil(timeLeft / 1000) / 60) | 0;
  const seconds = Math.ceil((timeLeft - minutes * 1000 * 60) / 1000);

  return `${padZero(minutes)}:${padZero(seconds)}`;
};

const TimerDisplay = React.memo(
  ({ timeLeft, degree, isGrabbing, mirror, ...svgProps }) => {
    return (
      <svg
        className="unselectable"
        style={{
          cursor: isGrabbing ? "grabbing" : "grab"
        }}
        viewBox="0 0 130 130"
        {...svgProps}
      >
        <g id="watch" transform="translate(15, 15)">
          <TimerNumbers mirror={mirror} />
          <TimerTicks />
          <path
            style={mirror ? { transform: "scaleX(-1) translateX(-100px)" } : {}}
            fill="rgba(240, 0, 233, 0.9)"
            d={calculatePath(degree)}
          />
          <text
            fill="rgba(200, 200, 200, 0.6)"
            x="50"
            fontSize="10px"
            y="75"
            textAnchor="middle"
          >
            {prettyPrintTime(timeLeft)}
          </text>
        </g>
      </svg>
    );
  }
);

const Timer = ({ mirror, onTimerStarted, initialFinishTime }) => {
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [timeLeft, setTimeLeft] = useState(undefined);
  const [finishTime, setFinishTime] = useState(initialFinishTime);
  const [isRunning, setRunning] = useState(!!initialFinishTime);

  // Degree is where the watchface is at
  const [degree, setDegree] = useState(null);

  useEffect(() => {
    if (!initialFinishTime) return;
    const timeLeft = initialFinishTime - new Date().getTime();
    if (timeLeft < 0) return;

    setFinishTime(initialFinishTime);
    const fullDegree = (timeLeft / (60 * 60 * 1000)) * 360;
    setDegree(fullDegree);
    setRunning(true);
  }, [initialFinishTime]);

  const startSetting = e => {
    tryAudio();
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    const isTouch = e.touches && e.touches.length === 1;
    const isMouse = typeof e.buttons !== "undefined" && e.buttons === 1;
    if (!isTouch && !isMouse) return;

    const boundingClientRect = e.currentTarget
      .querySelector("#watch")
      .getBoundingClientRect();
    const { width, height, top, left } = boundingClientRect;

    const mx = e.clientX || e.touches[0].clientX;
    const my = e.clientY || e.touches[0].clientY;
    const Mx = left + width / 2;
    const My = top + height / 2;

    const dx = Math.abs(mx - Mx);
    const dy = Math.abs(my - My);

    const d = (Math.sqrt(dx ** 2 + dy ** 2) / height) * 150;
    log(d, degree);
    if (d >= 70 && degree > 0) return;

    setRunning(false);
    setIsGrabbing(true);
  };

  const stopSetting = () => {
    setIsGrabbing(false);

    const minutes = (degree / 360) * 60;
    setFinishTime(new Date().getTime() + minutes * 60 * 1000);
    setRunning(true);
  };

  useEffect(() => {
    if (typeof finishTime === "undefined" || !isRunning) return;
    const timeLeft = finishTime - new Date().getTime();
    if (timeLeft < 0) {
      setTimeLeft(0);
      setDegree(0);
    }

    onTimerStarted(new Date(finishTime));

    const update = () => {
      if (!isRunning) return;
      const timeLeft = finishTime - new Date().getTime();
      if (timeLeft < 0) {
        setTimeLeft(0);
        setRunning(false);
        setDegree(0);
        audio.src = bell;
        audio.play();
        window.clearInterval(id);
        return;
      }
      const fullDegree = (timeLeft / (60 * 60 * 1000)) * 360;
      const smoothDegree = Math.floor(fullDegree * 100) / 100;
      setTimeLeft(timeLeft);
      setDegree(smoothDegree);
    };
    let id = window.setInterval(update, 250);
    update();

    return () => {
      log("Disposed");
      window.clearInterval(id);
    };
  }, [finishTime, isRunning, onTimerStarted]);

  const setTime = useCallback(
    e => {
      if (e.stopPropagation) e.stopPropagation();
      if (e.preventDefault) e.preventDefault();
      if (!isGrabbing) return;
      const isTouch = e.touches && e.touches.length === 1;
      const isMouse = typeof e.buttons !== "undefined" && e.buttons === 1;
      if (!isTouch && !isMouse) return;

      const boundingClientRect = e.currentTarget.getBoundingClientRect();
      const { width, height, top, left } = boundingClientRect;

      const mx = (e.clientX || e.touches[0].clientX) - left;
      const my = (e.clientY || e.touches[0].clientY) - top;

      const x = ((mirror ? -1 : 1) * (mx - width / 2)) / (width / 2);
      const y = -(my - height / 2) / (height / 2);

      let newDegree = (Math.atan2(y, x) * 180) / Math.PI - 90;
      if (x < 0 && y > 0) {
        newDegree = 360 - newDegree;
      } else {
        newDegree = Math.abs(newDegree);
      }

      newDegree = Math.min(359.999, newDegree);
      if (degree > 350 && newDegree < 180) newDegree = 359.999;
      if (degree < 10 && newDegree > 180) newDegree = 0;

      setDegree(newDegree);
    },
    [isGrabbing, degree, mirror]
  );

  return (
    <TimerDisplay
      timeLeft={timeLeft}
      isGrabbing={isGrabbing}
      onMouseDown={startSetting}
      onTouchStart={startSetting}
      onTouchMove={setTime}
      onTouchEnd={stopSetting}
      onTouchCancel={stopSetting}
      onMouseMove={setTime}
      onMouseUp={stopSetting}
      mirror={mirror}
      degree={degree}
    />
  );
};

const AudioEnablerPopup = ({ onClose }) => (
  <div className="popup--outer">
    <div className="popup--inner">
      <p>Please press this button to enable playing sounds.</p>
      <button
        onClick={() => {
          assertAudioEnabled().then(isEnabled => {
            return isEnabled && onClose();
          });
        }}
      >
        Enable Audio
      </button>
    </div>
  </div>
);

const useToggle = initialState => {
  const [get, set] = useState(initialState);
  return [get, () => set(true), () => set(false)];
};

function App() {
  const [initialFinishTime, setInitialFinishTime] = useState(undefined);
  const [
    doShowAudioEnablerPopup,
    showAudioEnablerPopup,
    hideAudioEnablerPopup
  ] = useToggle(false);

  useEffect(() => {
    if (window.location.hash !== "") {
      const fromLocation = new Date(window.location.hash.slice(1)).getTime();
      if (fromLocation <= new Date().getTime()) return;
      setInitialFinishTime(fromLocation);
    }
  }, []);

  const onTimerStarted = useCallback(
    finishTime => {
      assertAudioEnabled().then(isEnabled => {
        isEnabled || showAudioEnablerPopup();
      });

      window.location.hash = finishTime.toISOString();
    },
    [showAudioEnablerPopup]
  );

  const setTimer = min => e => {
    assertAudioEnabled();
    log("Button press");
    setInitialFinishTime(new Date().getTime() + min * 60 * 1000);
  };

  const handleClickTouch = fn => {
    const isTouchDevice =
      "ontouchstart" in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch);

    if (isTouchDevice) return { onTouchEnd: fn };
    return { onClick: fn };
  };

  return (
    <React.Fragment>
      <pre id="log"></pre>
      <div className="app">
        <Timer
          mirror={false}
          initialFinishTime={initialFinishTime}
          onTimerStarted={onTimerStarted}
        />
        <div className="buttons unselectable">
          <button {...handleClickTouch(setTimer(25))}>25min</button>
          <button {...handleClickTouch(setTimer(10))}>10min</button>
          <button {...handleClickTouch(setTimer(5))}>5min</button>
          <button {...handleClickTouch(setTimer(5 / 60))}>5s</button>
        </div>
        <footer>
          <a href="https://freesound.org/people/Benboncan/sounds/66951/">
            CC-BY-SA: Bell Sound
          </a>
          <span> - {version}</span>
        </footer>
      </div>
      {doShowAudioEnablerPopup && (
        <AudioEnablerPopup onClose={hideAudioEnablerPopup} />
      )}
    </React.Fragment>
  );
}

export default App;
